import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { setupPlan4u } from "shared/utils";
import { getLocations } from "features/LocationsPage/locations";
import { LanguageChooser } from "shared/components/LanguageChooser";
import halloweenBanner from "assets/images/halloweenBanners/baner_halloween_8.png";
import warsawBanner from "assets/images/banner-warsaw-v7.png";
import katowiceBanner from "assets/images/katowice-banner-v2.png";
import gdanskBanner from "assets/images/banner-gdansk.png";

import { Header } from "shared/components/BasicHeader";
import css from "./styles.module.scss";
import { Spinner } from "shared/components/Spinner";
import { Text } from "shared/components/Text";
import { Button } from "shared/components/Button";

function receiveMessage(e) {
  try {
    //if (e.origin.includes("your-iframe-domain.com"))
    if (typeof e.data === "number") {
      window.scrollTo(0, e.data, { behavior: "smooth" });
    }
  } catch (err) {}
}

export const BookingPage = () => {
  const { t, i18n } = useTranslation();
  const { city } = useParams();
  const [isLoading, setIsLoading] = useState(true);

  const locations = useMemo(() => {
    return getLocations();
  }, [i18n.language]);

  const bookingUrl = useMemo(() => {
    return locations[city].bookingUrl[i18n.language];
  }, [i18n.language, locations, city]);

  // const bannerImage = useMemo(() => {
  //   return locations[]
  // })

  useEffect(() => {
    document.title = `PixelXL Booking - ${city}`;

    window.addEventListener("message", receiveMessage);

    return () => {
      window.removeEventListener("message", receiveMessage);
    };
  }, []);

  const navigate = useNavigate();

  return (
    <div className={css.BookingPage}>
      <div className={css.languageChooserContainer}>
        <LanguageChooser
          className={css.languageChooser}
          shouldScrollUp
          borderColor="blue"
        />
      </div>
      <Header city={city} cityName={locations[city].title} />
      <img src={locations[city].bookingPageBanner} className={css.banners} />
      {isLoading && <Spinner type="small" />}
      <iframe
        id="fBookIt"
        src={bookingUrl}
        width="100%"
        frameBorder="0"
        scrolling="no"
        className={css.iframe}
        onLoad={() => {
          setupPlan4u();
          setIsLoading(false);
        }}
      ></iframe>
    </div>
  );
};
