import i18n from "i18n";

export const getOffers = () => [
  {
    name: i18n.t("careerSection.jobs.customerService"),
    city: i18n.t("careerSection.warsaw"),
    link: "https://www.olx.pl/oferta/praca/host-obsluga-klienta-pixel-xl-CID4-ID11S6cE.html?bs=olx_pro_listing",
  },
  {
    name: "Game Designer",
    city: i18n.t("careerSection.wroclaw"),
    link: "https://justjoin.it/offers/pixel-xl-game-designer-wroclaw-game",
  },
  {
    name: "Game Developer",
    city: i18n.t("careerSection.wroclaw"),
    link: "https://justjoin.it/offers/pixel-xl-javascript-game-developer-wroclaw-game",
  },
  {
    name: i18n.t("careerSection.jobs.architect"),
    city: i18n.t("careerSection.wroclaw"),
    link: "https://www.pracuj.pl/praca/architekt-wroclaw,oferta,1003623778",
  },
  {
    name: i18n.t("careerSection.jobs.objectManager"),
    city: i18n.t("careerSection.wroclaw"),
    link: "https://www.pracuj.pl/praca/manager-obiektu-wroclaw-swidnicka-12,oferta,1003644074",
  },
  {
    name: i18n.t("careerSection.jobs.performaceSpecialistMarkering"),
    city: i18n.t("careerSection.wroclaw"),
    link: "https://www.pracuj.pl/praca/specjalista-specjalistka-ds-performance-marketingu-wroclaw,oferta,1003633142",
  },
];
